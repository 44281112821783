import React from 'react';
import { Button as ChakraButton, StatLabel } from '@chakra-ui/react';
import { Box, Stat } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Layout } from 'antd';

const { Content } = Layout;

type AppTemplateProps = {
  children2: React.ReactNode;
  headerTitle: React.ReactElement;
  headerBody?: React.ReactElement;
};

export const AppTemplate: React.FC<AppTemplateProps> = ({ headerTitle, headerBody, children, children2 }) => {
  return (
    <Layout
      style={{
        height: '100vh',
        backgroundImage: `url(/bg.jpg)`,
        backgroundSize: 'cover',
        width: '100vw',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <StatStyled style={{ textAlign: 'center', fontSize: 15, marginTop: '24px' }}></StatStyled>
      <a
        style={{ alignSelf: 'center' }}
        href='https://docs.google.com/spreadsheets/d/1s4s8LseoDFcH-rV8XpYeZnkdx-pVYaSkgzeI3EMi8vI/edit?usp=sharing'
      >
        <ChakraButton size='sm' colorScheme='green'>
          Общий список нецелевых клиентов и негативщиков
        </ChakraButton>
      </a>
      <OuterContainer>
        <Paper>
          <ContentContainer>
            <Box p={1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <StatStyled>{headerTitle}</StatStyled>
            </Box>
            {children}
          </ContentContainer>
        </Paper>
      </OuterContainer>
      <OuterContainer>
        <Paper>
          <ContentContainer>
            <Box p={1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <StatStyled>{headerBody}</StatStyled>
            </Box>
            {children2}
          </ContentContainer>
        </Paper>
      </OuterContainer>
    </Layout>
  );
};

export const StatStyled = styled(Stat)`
  margin-top: 8px;
  @media (max-width: 425px) {
    & > dl > dt {
      font-size: var(--chakra-fonts-body);
    }

    & > dl > dd {
      font-size: var(--chakra-fonts-body);
    }
  }

  @media (max-width: 350px) {
    & > dl > dt {
      font-size: var(--chakra-fonts-body);
    }

    & > dl > dd {
      font-size: var(--chakra-fonts-body);
    }
  }
`;

const OuterContainer = styled(Content)`
  min-height: 70%;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Box)`
  display: flex;
  margin: 0 auto;
  //width: 340px;
  padding: 10px 10px;
  flex-direction: column;
  box-shadow: 7px 4px 14px 2px rgba(34, 60, 80, 0.2);
  background: white;
  border-radius: 28px;

  @media (max-width: 425px) {
    width: 95%;
  }
`;

const Paper = styled(Box)`
  height: 100%;
  padding: var(--chakra-space-6);

  @media (max-width: 425px) {
    padding: var(--chakra-space-1);
  }
`;
