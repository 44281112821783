import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
  Stack,
  StatLabel,
} from '@chakra-ui/react';
import { AppTemplate, StatStyled } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas, sendLeadToVakas1 } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useDebouncedValue } from '../../shared/hook/use-debounced-value';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const firebaseConfig = {
  apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
  authDomain: 'missis-laser.firebaseapp.com',
  projectId: 'missis-laser',
  storageBucket: 'missis-laser.appspot.com',
  messagingSenderId: '514174044584',
  appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
  measurementId: 'G-QTNN569ZLV',
};

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [error1, setError1] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  const [errorText1, setErrorText1] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [username1, setUserName1] = useState<string>('');
  //* ==================
  const [comment, setComment] = useState<string>('');
  const [comment1, setComment1] = useState<string>('');
  //* ==================
  const [phone, setPhone] = useState<string>('');
  const [phone1, setPhone1] = useState<string>('');

  const effectiveQuery1 = useDebouncedValue(phone, 2000);
  const effectiveQuery2 = useDebouncedValue(phone1, 2000);

  React.useEffect(() => {
    if (!effectiveQuery1 || effectiveQuery1 === '') return;
    const clearPhone = (phone: string) => phone.split(' ').join('').split('-').join('');
    if (effectiveQuery1[0] === '+' && effectiveQuery1[1] === '7' && effectiveQuery1[2] === '9') {
      setPhone1(clearPhone(effectiveQuery1).slice(2, 12));
    } else if (effectiveQuery1[0] === '7' && effectiveQuery1[1] === '9') {
      setPhone1(clearPhone(effectiveQuery1).slice(1, 11));
    } else if (effectiveQuery1[0] === '8' && effectiveQuery1[1] === '9') {
      setPhone1(clearPhone(effectiveQuery1).slice(1, 11));
    } else if (effectiveQuery1[0] === '9') {
      setPhone1(clearPhone(effectiveQuery1).slice(0, 10));
    } else {
      setError1(true);
      setErrorText1('Не удалось обработать номер телефона');
      setPhone1('');
    }
  }, [effectiveQuery1]);

  React.useEffect(() => {
    if (!effectiveQuery2 || effectiveQuery2 === '') return;

    const clearPhone = (phone: string) => phone.split(' ').join('').split('-').join('');
    if (effectiveQuery2[0] === '+' && effectiveQuery2[1] === '7' && effectiveQuery2[2] === '9') {
      setPhone(clearPhone(effectiveQuery2).slice(2, 12));
    } else if (effectiveQuery2[0] === '7' && effectiveQuery2[1] === '9') {
      setPhone(clearPhone(effectiveQuery2).slice(1, 11));
    } else if (effectiveQuery2[0] === '8' && effectiveQuery2[1] === '9') {
      setPhone(clearPhone(effectiveQuery2).slice(1, 11));
    } else if (effectiveQuery2[0] === '9') {
      setPhone(clearPhone(effectiveQuery2).slice(0, 10));
    } else {
      setError(true);
      setErrorText('Не удалось обработать номер телефона');
      setPhone('');
    }
  }, [effectiveQuery2]);

  //* ==================
  const [city, setCity] = useState<string>('');
  const [city1, setCity1] = useState<string>('');

  const history = useHistory();

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  React.useEffect(() => {
    logEvent(analytics, window.location.hostname);
  }, [analytics]);

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        city,
        name: username,
        phone: '+7' + phone,
        comment,
      }).then(() => history.push(Routes.Success));
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  const onSubmit1 = async () => {
    try {
      await sendLeadToVakas1({
        city: city1,
        name: username1,
        phone: '+7' + phone1,
        comment: comment1,
      }).then(() => history.push(Routes.Success));
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  return (
    <AppTemplate
      headerTitle={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>ФОРМА ДЛЯ ДОБАВЛЕНИЯ НЕГАТИВЩИКА</StatLabel>
      }
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
          ФОРМА ДЛЯ ДОБАВЛЕНИЯ КЛИЕНТА ИЗ НЕЦЕЛЕВОГО ГОРОДА
        </StatLabel>
      }
      children2={
        <Box
          p={0}
          overflowY='auto'
          flexGrow={1}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Stack spacing='2rem'>
            <Stack spacing='1rem' alignItems='center'>
              <InputGroup style={{ maxWidth: '340px', marginTop: 24 }}>
                <Input
                  maxLength={30}
                  /* isInvalid={error} */
                  placeholder='Имя'
                  value={username1}
                  onChange={e => {
                    setUserName1(e.target.value);
                    setError1(false);
                  }}
                />
              </InputGroup>
              <InputGroup style={{ maxWidth: '340px' }}>
                <InputLeftAddon children='+7' />
                <Input
                  maxLength={20}
                  //type='tel'
                  /* isInvalid={error} */
                  placeholder='Номер телефона'
                  value={phone1}
                  onChange={event => {
                    /* isNumeric(event.target.value) && */ setPhone1(event?.target.value);
                    setError1(false);
                  }}
                />
              </InputGroup>
              <InputGroup style={{ maxWidth: '340px', marginTop: 24 }}>
                <Input
                  maxLength={30}
                  /* isInvalid={error} */
                  placeholder='Нецелевой город'
                  value={city1}
                  onChange={e => {
                    setCity1(e.target.value);
                    setError1(false);
                  }}
                />
              </InputGroup>
              <InputGroup style={{ maxWidth: '340px', marginTop: 24 }}>
                <Textarea
                  maxLength={300}
                  /* isInvalid={error} */
                  placeholder='Комментарий (Необязательно)'
                  value={comment1}
                  onChange={e => {
                    setComment1(e.target.value);
                    setError1(false);
                  }}
                />
              </InputGroup>
              <StatStyled>
                <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
                  ❤️ Добавьте клиента из нецелевого города в список <br />
                </StatLabel>
              </StatStyled>
            </Stack>
            {error1 && (
              <Stack spacing='1rem' style={{ marginTop: 12 }}>
                {errorText1 && (
                  <Alert status='error'>
                    <AlertIcon />
                    {errorText1}
                  </Alert>
                )}
              </Stack>
            )}
            <Button
              //disabled={city === "" || friend_phone === "" || friend_name === "" || phone === "" || username === ""}
              onClick={() => {
                if (city1 === '' || phone1 === '' || username1 === '') {
                  setError1(true);
                  setErrorText1(
                    city1 === ''
                      ? 'Вы не указали город'
                      : phone1 === ''
                      ? 'Вы не указали номер'
                      : username1 === ''
                      ? 'Вы не указали имя'
                      : null
                  );
                } else {
                  onSubmit1();
                }
              }}
              mt='24px'
              w='100%'
              h='60px'
              alignSelf='center'
              colorScheme='teal'
              variant='solid'
              style={{ marginBottom: '16px', maxWidth: '340px' }}
            >
              Отправить
            </Button>
          </Stack>
        </Box>
      }
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem' alignItems='center'>
            <InputGroup style={{ maxWidth: '340px', marginTop: 24 }}>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup style={{ maxWidth: '340px' }}>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={20}
                //type='tel'
                /* isInvalid={error} */
                placeholder='Номер телефона'
                value={phone}
                onChange={event => {
                  /* isNumeric(event.target.value) && */ setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup style={{ maxWidth: '340px' }}>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Город'
              >
                <option value='Москва'>Москва</option>
                <option value='Санкт-Петербург'>Санкт-Петербург</option>
                <option value='Казань'>Казань</option>
                <option value='Уфа'>Уфа</option>
                <option value='Тверь'>Тверь</option>
                <option value='Неизвестно'>Неизвестно</option>
              </Select>
            </InputGroup>
            <InputGroup style={{ maxWidth: '340px' }}>
              <Textarea
                maxLength={300}
                /* isInvalid={error} */
                placeholder='Комментарий (Необязательно)'
                value={comment}
                onChange={e => {
                  setComment(e.target.value);
                  setError1(false);
                }}
              />
            </InputGroup>

            <StatStyled>
              <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
                ❤️ Добавьте негативщика в список <br />
              </StatLabel>
            </StatStyled>
          </Stack>
          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}
          <Button
            //disabled={city === "" || friend_phone === "" || friend_name === "" || phone === "" || username === ""}
            onClick={() => {
              if (city === '' || phone === '' || username === '') {
                setError(true);
                setErrorText(
                  city === ''
                    ? 'Вы не указали город'
                    : phone === ''
                    ? 'Вы не указали номер'
                    : username === ''
                    ? 'Вы не указали имя'
                    : null
                );
              } else {
                onSubmit();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
            style={{ marginBottom: '16px', maxWidth: '340px' }}
          >
            Отправить
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
