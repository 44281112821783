import { useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'

export function useDebouncedValue<T> (value: T, ms: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useDebounce(() => {
    setDebouncedValue(value)
  }, ms, [value])
  return debouncedValue
}
